<template>  
    <div class="container-fluid">
            <!--
            <div class="row" style="margin-bottom: 0px;">
              <div class="col-12 hidden-scrollbar" style="overflowX: hidden; overflowY: hidden;">
                <div class="mr-auto ml-auto" style="display: flex; flex-wrap: wrap; min-width: fit-content;">
            <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      controls
      indicators
      fade
      background="#ababab"
      img-width="1024"
      img-height="100%"
      style="text-shadow: 1px 1px 2px #333;"      
    >
     
      
        <template v-for="rs in $store.state.fleet.roomsetList">
          <b-carousel-slide :key="rs.oid" :caption="rs.label">
          <template v-slot:img>
            <div class="row" style="margin-bottom: 0px; min-vh-100">
              <div class="col-12 hidden-scrollbar" style="overflowX: hidden; overflowY: hidden;">
                <div class="mr-auto ml-auto" style="display: flex; flex-wrap: wrap; min-width: fit-content;">
                  <template v-for="room in rs.roomList" v-bind="room.oid" >
                        
                   <div :key="room.oid" style="width: 430px; marginRight: 15px">
                    <ISARoom :room="room" :roomset="rs" ></ISARoom>        
                   </div>
                  </template>                                 
                </div>
            </div>
          </div>                     
          </template>
        </b-carousel-slide>
        
         
        </template>
        </b-carousel>
        </div>
            </div>
          </div>     
		  -->   
		  <div class="row" style="margin-bottom: 0px;">
              <div class="col-12 hidden-scrollbar" style="overflowX: hidden; overflowY: hidden;">
                <div class="mr-auto ml-auto" style="display: flex; flex-wrap: wrap; min-width: fit-content;" id="kiosk_sensors">
                  <template v-for="rs in $store.state.fleet.roomsetList.filter(r => r.organizationLabel === $store.state.fleet.currentOrganization && r.label.charAt(0) !== '_' )">
                  <template v-for="room in rs.roomList" v-bind="room.oid" v-if="$store.state.fleet.visibilityC >= 0 && $store.state.fleet.visibility[rs.oid]">
                    <div :key="room.oid" :class="roomClass" >                  
                      <ISARoom  :room="room" :roomset="rs" ></ISARoom>        
                    </div>
                  </template>
						</template>
				</div>
            </div>
          </div>  
    </div>              
</template>
<script>  
  import ISARoom from '@/components/ISA/kiosk/ISARoom.vue'
  

  export default {
    components: {      
      ISARoom
	},
	mounted() {    
    const self = this;
		window.addEventListener('resize', () => {
      
      self.roomClass = self.computeRoomClass();
    })
    this.roomClass = this.computeRoomClass();
	},
    data () {
      return {
        slide:0,      
        physicalScreenWidth: 100,  
        physicalScreenHeight: 100,    
        roomClass: "col-12"   ,
      }           
  },
  watch: {
    "$store.state.fleet.visibilityC": function(val) {
      this.computeRoomClass();
    },
  },
  
  methods: {
    computeRoomClass() {
    let physicalScreenWidth = (window.ismobile || window.isandroid) ? window.screen.width  : window.innerWidth;
    let physicalScreenHeight = this.$store.state.user.isMobile ? window.screen.height : window.innerHeight;
    let rss =  this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization  && r.label.charAt(0) !== '_' && this.$store.state.fleet.visibilityC >= 0 && this.$store.state.fleet.visibility[r.oid]);
    let roomCount = 0;
    for(let rs of rss) roomCount+= rs.roomList.length;
    // console.log('sensors counts=' + roomCount)    
    let w1 = 350;
    let w2 = 450;
    if(roomCount > 23) w2 = 330; // 6 per line

    let w = (window.ismobile || window.isandroid) ? w1 : w2;


    let c = Math.floor(physicalScreenWidth / w);
    // console.log('number of rooms per line = ' +  physicalScreenWidth  + ' / ' + w + ' --> ' + c);
/*
      if(physicalScreenWidth > 2000)
          return "col-3";
      else return "col-12";

      if(this.$store.state.user.isMobile)
        return "col-12";
      else return "col-3";*/
  
      switch(c) {
        case 0: { return "col-12"; break; }
        case 1: { return "col-12"; break; }
        case 2: { return "col-6"; break; }
        case 3: { return "col-4"; break; }
        case 4: { return "col-3"; break; }
        case 5: { return "col-2"; break; }
        case 6: { return "col-2"; break; }
        default: { return "col-2"; break; }
      }      
    }
    
  },

 
  }
</script>
<style lang="scss" scoped>

.main-content {
	width: 100%;
}

.card {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.isakiosk {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .isakiosk {
    max-width: none; 
  }
}

@media (min-width: 768px) {
  .isakiosk {
    max-width: none; 
  }
}

@media (min-width: 992px) {
  .isakiosk {
    max-width: none; 
  }
}

@media (min-width: 1200px) {
  .isakiosk {
    max-width: none; 
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
