<template>
  
    <b-modal
      hide-footer
      v-model="showNotificationHistory"
      :title="($store.state.fleet.watchingVideoAlert ? ($store.state.fleet.watchingVideoAlert.room  + ' / ' +  $store.state.fleet.watchingVideoAlert.date) : '' )"
      @hidden="close"      
      >
        
        <div class="wrapper">
        <div class="row">
        <b-col cols="12">          
          <p>Room: {{$store.state.fleet.watchingNotificationHistoryData ? $store.state.fleet.watchingNotificationHistoryData.room : ''}}</p>
          <p>Type: {{$store.state.fleet.watchingNotificationHistoryData ? ($store.state.fleet.watchingNotificationHistoryData.type === 4 ? 'Bed Exit' : 'Fall') : ''}}</p>
          <p>Event Date: {{$store.state.fleet.watchingNotificationHistoryData ? momentutc($store.state.fleet.watchingNotificationHistoryData.date).local().format('YYYY-MM-DD HH:mm:ss') : ''}}</p>          
        </b-col>
        <b-col cols="12" v-if="$store.state.fleet.watchingNotificationHistoryData">
        
          <template v-for="(t, index) in $store.state.fleet.watchingNotificationHistoryData.twilio_list">
            <p :key="index" v-if="t.To && !t.To.startsWith('+230')"><span style="color: blue;">{{moment(t.Timestamp).utc().format('HH:mm:ss')}}</span> <span style="color: grey">({{t.To}})</span> status is <span style="font-weight: bold">{{t.CallStatus}}</span> <span v-if="t.CallDuration && t.CallDuration > 0">({{t.CallDuration}}s)</span></p>
          </template>
        </b-col>
        
        </div>
        </div>
      
    </b-modal>  
</template>

<script>
import moment from 'moment';

export default  {
  components: {
  },
  mounted() {
    this.$store.dispatch('fleet/watchNotificationhistory',null);
  },
  data() {
    return {
      showNotificationHistory: false
    }
  },
  watch : {
    '$store.state.fleet.watchingNotificationHistory': function(val) {      
      if(val === false)
        this.showNotificationHistory = false
     else 
        this.showNotificationHistory = val != null || val === true
      }
  },
  computed: {
    show() {
       return this.$store.state.fleet.watchingNotificationHistoryData
    },
  },
  methods: {
    moment,
    momentutc: moment.utc,
    close() {
      this.$store.dispatch('fleet/watchNotificationhistory',null)
    },
  },
  name: "ISANotificationHistory"
}
</script>

<style lang="scss">
.isapreviewimg {
  width: 100%
}
</style>
