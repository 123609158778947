import Vue from "vue";
export class OneSignalService {
  /**
   ******************************
   * @API
   ******************************
   */
  static actionMessage = "Do you want to receive alert notifications on this device ?";
  static acceptButtonText ="ALLOW";
  static cancelButtonText = "NO THANKS";
  static title = "MyISA notification subscription";
  static message = "Thanks for subscribing!";


  static async setIdentifierCordova(userOID, email, organization) {
    try
    {                   
    let _OneSignal = null
    if(window.plugins && window.plugins.OneSignal)
        _OneSignal= window.plugins.OneSignal;

    if(!_OneSignal) return;
    
    if(organization)    
        _OneSignal.sendTags({        
        organization: organization,
        })
    if(userOID) _OneSignal.setExternalUserId(userOID);

    if(email) _OneSignal.setEmail(email);       

} catch(err) {
    console.error(err);
}
  }

  static async setIdentifierWeb(userOID, email, organization) {
    try
    {           
    
    let _OneSignal = window.OneSignal    
    if(!_OneSignal) return;
    
    if(organization)
    _OneSignal.push(function() {
        _OneSignal.sendTags({        
      organization: organization,
    }).then(function(tagsSent) {
      // Callback called when tags have finished sending              
      console.log(tagsSent);
    });
    if(userOID)
    _OneSignal.push(function() {
        _OneSignal.setExternalUserId(userOID);
    });

    if(email)
    _OneSignal.push(function() {
        _OneSignal.setEmail(email)          
        .then(function(emailId) {
          // Callback called when email have finished sending
          console.log("emailId: ", emailId);  
        }); 
    });
  });
} catch(err) {
    console.error(err);
}
  }

  static async setIdentifiers(userOID, email, organization) {      
    if(window.plugins && window.plugins.OneSignal) {
        return OneSignalService.setIdentifierCordova(userOID, email, organization)        
    } else if (window.OneSignal) {
        return OneSignalService.setIdentifierWeb(userOID, email, organization)      ;
    }
  }
  static async initOneSignalCordova() {
    //START ONESIGNAL CODE
      //Remove this method to stop OneSignal Debugging   
      
        
      window.plugins.OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
      
      var notificationOpenedCallback = function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };
      // Set your iOS Settings
      var iosSettings = {};
      iosSettings["kOSSettingsKeyAutoPrompt"] = false;
      iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;
      
      window.plugins.OneSignal
        .startInit("82541af0-b593-4759-8662-b7953a8df6ad")
        .handleNotificationOpened(notificationOpenedCallback)
        .iOSSettings(iosSettings)
        .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
        .endInit();
      
      // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
      window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);    
      });

      window.plugins.OneSignal.enableVibrate(true);
      window.plugins.OneSignal.enableSound(true);
      //END ONESIGNAL CODE
  }

  static async initOneSignalWeb() {      
    
    var OneSignal = window.OneSignal || [];
    OneSignal.push(function() {
      OneSignal.init({
        appId: "82541af0-b593-4759-8662-b7953a8df6ad",
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
        requiresUserPrivacyConsent: false,
        autoResubscribe: true,
        notifyButton: {
          enable: false,
        },
        promptOptions: {
          slidedown: {
            enabled: true,
            autoPrompt: true,
            timeDelay: 3,
            pageViews: 0,
            actionMessage: OneSignalService.actionMessage,
            acceptButtonText: OneSignalService.acceptButtonText,
            cancelButtonText: OneSignalService.cancelButtonText,
            icon: '/img/isalogo.png'
          }
        },
        welcomeNotification: {
          "title": OneSignalService.title,
          "message": OneSignalService.message,
          // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
        }
      });
    });
  }


  static async init() {
    if(window.plugins && window.plugins.OneSignal) {
        return OneSignalService.initOneSignalCordova()        
    } else if (window.OneSignal) {
        return OneSignalService.initOneSignalWeb()      ;
    }
    
  }

}