<template>
  
    <b-modal
      size="lg"
      hide-footer
      v-model="showRoomWatcher"
      :title="$store.state.fleet.watchingLabel"
      @hidden="close"
      >
      <div v-if="!isEnabled && hasLicense" class="container h-100">
        <span>
            This feature has been disabled by your administrator.
        </span>
      </div>
      <div v-if="!hasLicense" class="container h-100">
        <span>
            Your current ISA subscription level does not allow you to visualize real time images.
            Please contact your administrator.
        </span>
      </div>
      <div v-if="isEnabled && hasLicense" class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <GridLoader :loading="!hasPreview" size="80px" class="align-items-center"></GridLoader>
            <img v-if="hasPreview" class="isapreviewimg" :src="livePreview"/>      
        </div>
        <div class="row justify-content-center align-items-center">
          <span v-text="lastDate"></span>
        </div>
        
          <b-button @click="DisableCloseFollowUp" style="margin-top: 20px" id="kiosk_disableroomfollowup">
            <span><font-awesome-icon icon="eye-slash" /></span>           
            {{ $t('Disable Follow Up for this Room') }}
          </b-button>          
          <b-button @click="close" variant="primary" style="margin-top: 20px" class="float-right">            
            {{ $t('Close') }}
          </b-button>          
        
      </div>
      
    </b-modal>  
</template>

<script>
import GridLoader from 'vue-spinner/src/ClipLoader.vue'
import moment from 'moment'
import { SPService } from '@/services/sp.service'
import { API_URL } from '@/.env'


export default  {
  components: {    
    GridLoader,    
  },  
  data() {
    return {
      showRoomWatcher: false    ,
      lastDate: 'Loading...',
      secondChangeInterval: '',
      lastLoad: null,
    }
  },
  computed: {
    hasLicense() {
      for(let rs of this.$store.state.fleet.roomsetList)
        if(rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.roomList)
        for(let r of rs.roomList) {
          if(r.oid === this.$store.state.fleet.watchingOid) {
              return rs.licensing && rs.licensing.closeFollowUp;
          }
        }
      return false;
    },
    isEnabled() {
        for(let rs of this.$store.state.fleet.roomsetList)
        if(rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.roomList)
        for(let r of rs.roomList) {
          if(r.oid === this.$store.state.fleet.watchingOid) {
              return rs.licensing && rs.featureCloseFollowUpEnabled;
          }
        }
      return false;
    },
    hasPreview() {
      return this.$store.state.fleet.watchingImgLoaded != null
    },
    livePreview() {
      return 'data:image/png;base64,' + this.$store.state.fleet.watchingImgLoaded
    },
    action() {
      return this.$store.state.fleet.watchingImgLoaded == null ? '' : 'Live from ' + this.$store.state.fleet.watchingLabel
    },
    imgDate() {
      
    }
  },
   watch : {
    '$store.state.fleet.watching': function(val) {      
      if(val === false)
        this.showRoomWatcher = false
     else  {
        this.showRoomWatcher = val != null || val === true
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'followup',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
     }
    },
    '$store.state.fleet.watchingImgLoaded': function(val) {
      this.lastLoad = new Date();
      let d = moment(this.$store.state.fleet.watchingImgLoadedDate);    
      if(d.isValid())  
        this.lastDate = this.$t('Last acquisition: ') + d.format('hh:mm:ss')
      else this.lastDate = this.$t('loading...')
    }
  },    
  methods: {       
      close()  {
        this.$store.commit('fleet/SET_WATCHING_ROOM',{ enable: false})
      },
      async DisableCloseFollowUp() {
      const oid = this.$store.state.fleet.watchingOid;
      const dispatch = this.$store.dispatch;
      const commit = this.$store.commit;
        this.$dialog
          .confirm(this.$t('This will disable Follow Up for this Room.\nAre you sure?'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(function(dialog) {
          console.log('disabling follow up')
            dispatch('fleet/disableCloseFollowUp',oid)
            commit('fleet/SET_WATCHING_ROOM',{ enable: false})
          })
          .catch(function() {
            
          });        
      },
      tick() {
        if(!this.$store.state.fleet.watching) return;
        if(!this.hasLicense) return;

        if(!this.lastLoad || (new Date() - this.lastLoad) > 10000)
          SPService.askPreview(API_URL.url, this.$store.state.user.currentUser.jwt, this.$store.state.fleet.watchingOid)

      }
    },
    mounted() {
      this.$store.commit('fleet/SET_WATCHING_ROOM',{ enable: false})
      this.secondChangeInterval = setInterval(this.tick.bind(this),2000);
    },
    
  name: "ISARoomWatcherModal"
}
</script>

<style lang="scss">
.isapreviewimg {
  width: 45 rem;
}
</style>
