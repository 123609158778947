<template>  
  <div v-if="canAccessMedicalData()">
  <ISARoomWatcherModal/>          
  <ISANotificationHistoryModal/>        
  <ISASound/>
          
  <ISARoomset/>  
  </div>

  <div v-else>
     <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Access Denied') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("You don't have enough privileges to access to Kiosk")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('You need medical role, or medical administrator role to access this page. If you think that you should access to this page, please contact your administrator or mintt support at ') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import ISARoomset from '@/components/ISA/kiosk/ISARoomset';
import { mapActions } from "vuex";
import ISARoomWatcherModal from '@/components/ISA/kiosk/ISARoomWatcherModal';
import ISARoomBedExitSettingsModal from '@/components/ISA/kiosk/ISARoomBedExitSettingsModal';
import { AuthService } from "@/services/auth.service";

import ISANotificationHistoryModal from '@/components/ISA/kiosk/ISANotificationHistoryModal';
import ISASound from '@/components/ISA/kiosk/ISASound';
import { OneSignalService } from '@/services/onesignal.service'

export default {
  metaInfo: {    
    title: "Kiosk"
  },
  components: {
    ISARoomset,
    ISASound,
    ISANotificationHistoryModal,    
    ISARoomBedExitSettingsModal,
    ISARoomWatcherModal,
  },
  async mounted() {
    this.closeSidebar();
      console.log('initializing onesignal...')
      if(this && this.$t) OneSignalService.actionMessage = this.$t("Do you want to receive alert notifications on this device ?");
      if(this && this.$t) OneSignalService.acceptButtonText = this.$t("ALLOW");
      if(this && this.$t) OneSignalService.cancelButtonText = this.$t("NO THANKS");
      if(this && this.$t) OneSignalService.title = this.$t("MyISA notification subscription");
      if(this && this.$t) OneSignalService.message = this.$t("Thanks for subscribing!");

      let oid = this.$store.state.user.currentUser.oid;
      let email = this.$store.state.user.currentUser.email;
      let currentOrganization = this.$store.state.fleet.currentOrganization;
      let role = this.$store.state.user.currentUser.role;
      await OneSignalService.init().then(() => {
           if(oid && role > 0)
              OneSignalService.setIdentifiers(oid, email,  currentOrganization);
      }
      )
               
  },
  methods: {
    ...mapActions([
      "closeSidebar",
      "changeThemeMode",
      "signOut"
    ]),
    canAccessMedicalData()  {
      return AuthService.canAccessKiosk(this.$store.state.user.currentUser.role);
    }
  }

};
</script>
